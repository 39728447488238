<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Brands</strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Product Group</th>
                  <th scope="col" class="text-left">Description</th>

                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('brand_read') ||
                      can('brand_update') ||
                      can('brand_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="brand in brands" :key="brand.id">
                  <td class="text-left">
                    {{ brand.name }}
                  </td>
                  <td class="text-left">{{ brand.product_group.name }}</td>
                  <td class="text-left">{{ brand.description }}</td>
                  <td
                    class="text-center"
                    v-if="
                      can('brand_read') ||
                      can('brand_update') ||
                      can('brand_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="showBrand(showModal, brand)"
                        v-if="can('brand_read')"
                      >
                        View</CButton
                      >
                      <CButton
                        color="info"
                        @click="editBrand(brand.id)"
                        v-if="can('brand_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteBrand(deleteModal, brand)"
                        color="danger"
                        v-if="can('brand_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <BrandView />
    <BrandDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const BrandView = () => import("@/views/Brand/BrandView");
const BrandDelete = () => import("@/views/Brand/BrandDelete");

export default {
  name: "Brands",
  components: {
    BrandDelete,
    BrandView,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("brands/getBrands");
  },
  computed: {
    ...mapState({
      pagination: (state) => state.brands.pagination,
      brands: (state) => state.brands.brands,
      isLoading: (state) => state.brands.isLoading,
      deleteModal: (state) => state.brands.deleteModal,
      showModal: (state) => state.brands.showModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("brands/getBrands", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    showBrand(bool, brand) {
      this.$store.commit("brands/SHOW_MODAL", {
        bool: bool,
        brand: brand,
      });
    },
    deleteBrand(bool, brand) {
      this.$store.commit("brands/DELETE_MODAL", {
        bool: bool,
        brand: brand,
      });
    },

    editBrand(brand_id) {
      this.$store.dispatch("brands/editBrand", {
        brand_id: brand_id,
      });
      this.$router.push({ name: "Edit Brand" });
    },
  },
};
</script>
